import React, { createContext, useContext, useEffect, useState } from 'react';

import { Mode, TransmissionLoss } from '@types';
import { Material } from '@utils/constants';
import { convertMetricVolume } from '@utils/helpers';

import helpers from './helpers';
import App from '../AppContext';

export interface ICalculatedContext {
  innerFloorSlabArea: number;
  innerFloorSlabVolume: number;
  innerRoofSlabArea: number;
  innerRoofSlabVolume: number;
  innerWallArea: number;
  innerWallLength1: number;
  innerWallLength2: number;
  innerWallTransmissionLoss: TransmissionLoss[];
  innerWallVolume: number;
  laborPrice: number;
  mamResonance1: number;
  mamResonance2: number;
  mamResonance3: number;
  modes: Mode[][];
  outerRoomHeight: number;
  outerWallArea: number;
  outerWallLength1: number;
  outerWallLength2: number;
  outerWallTransmissionLoss: TransmissionLoss[];
  outerWallVolume: number;
  outerFloorSlabArea: number;
  outerFloorSlabVolume: number;
  outerRoofSlabArea: number;
  outerRoofSlabVolume: number;
  overagePrice: number;
  price: number;
  roomArea: number;
  roomVolume: number;
  totalBuildingArea: number;
  totalBuildingHeight: number;
  totalBuildingVolume: number;
  totalConcreteMass: number;
  totalConcreteVolume: number;
  totalPrice: number;
  totalTransmissionLoss: TransmissionLoss[];
}

const CalculatedContext = createContext({} as ICalculatedContext);

const Provider = ({ children }: any) => {
  const { state: {
    costOfConcrete, innerFloorSlabThickness, innerRoofSlabThickness, innerWallThickness,
    laborMultiplier, leafDepth, outerFloorSlabThickness, outerRoofSlabThickness,
    outerWallThickness, overageMultiplier, roomHeight, roomLength1, roomLength2,
  } } = useContext(App.Context);

  const [innerFloorSlabArea, setInnerFloorSlabArea] = useState(0);
  const [innerFloorSlabVolume, setInnerFloorSlabVolume] = useState(0);
  const [innerRoofSlabArea, setInnerRoofSlabArea] = useState(0);
  const [innerRoofSlabVolume, setInnerRoofSlabVolume] = useState(0);
  const [innerWallArea, setInnerWallArea] = useState(0);
  const [innerWallLength1, setInnerWallLength1] = useState(0);
  const [innerWallLength2, setInnerWallLength2] = useState(0);
  const [innerWallTransmissionLoss, setInnerWallTransmissionLoss] = useState<TransmissionLoss[]>([]);
  const [innerWallVolume, setInnerWallVolume] = useState(0);
  const [laborPrice, setLaborPrice] = useState(0);
  const [mamResonance1, setMamResonance1] = useState(0);
  const [mamResonance2, setMamResonance2] = useState(0);
  const [mamResonance3, setMamResonance3] = useState(0);
  const [modes, setModes] = useState<Mode[][]>([]);
  const [outerRoomHeight, setOuterRoomHeight] = useState(0);
  const [outerWallArea, setOuterWallArea] = useState(0);
  const [outerWallLength1, setOuterWallLength1] = useState(0);
  const [outerWallLength2, setOuterWallLength2] = useState(0);
  const [outerWallTransmissionLoss, setOuterWallTransmissionLoss] = useState<TransmissionLoss[]>([]);
  const [outerWallVolume, setOuterWallVolume] = useState(0);
  const [outerFloorSlabArea, setOuterFloorSlabArea] = useState(0);
  const [outerFloorSlabVolume, setOuterFloorSlabVolume] = useState(0);
  const [outerRoofSlabArea, setOuterRoofSlabArea] = useState(0);
  const [outerRoofSlabVolume, setOuterRoofSlabVolume] = useState(0);
  const [overagePrice, setOveragePrice] = useState(0);
  const [roomArea, setRoomArea] = useState(0);
  const [roomVolume, setRoomVolume] = useState(0);
  const [price, setPrice] = useState(0);
  const [totalBuildingArea, setTotalBuildingArea] = useState(0);
  const [totalBuildingHeight, setTotalBuildingHeight] = useState(0);
  const [totalBuildingVolume, setTotalBuildingVolume] = useState(0);
  const [totalConcreteMass, setTotalConcreteMass] = useState(0);
  const [totalConcreteVolume, setTotalConcreteVolume] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalTransmissionLoss, setTotalTransmissionLoss] = useState<TransmissionLoss[]>([]);

  // Outer Room Height
  useEffect(() => {
    setOuterRoomHeight(roomHeight + leafDepth);
  }, [roomHeight, leafDepth]);

  // Inner Wall Lengths
  useEffect(() => {
    const length1 = roomLength1 + (innerWallThickness * 2);
    const length2 = roomLength2;

    setInnerWallLength1(length1);
    setInnerWallLength2(length2);
  }, [innerWallThickness, roomLength1, roomLength2]);

  // Outer Wall Lengths
  useEffect(() => {
    const length1 = innerWallLength1 + (outerWallThickness * 2);
    const length2 = innerWallLength2 + (innerWallThickness * 2);

    setOuterWallLength1(length1 + (leafDepth * 2));
    setOuterWallLength2(length2 + (leafDepth * 2));
  }, [innerWallLength1, innerWallLength2, innerWallThickness, leafDepth, outerWallThickness]);

  // Inner Room
  useEffect(() => {
    setRoomArea(roomLength1 * roomLength2);
    setRoomVolume(roomHeight * roomLength1 * roomLength2);
  }, [roomHeight, roomLength1, roomLength2]);

  // Inner Wall Volume
  useEffect(() => {
    setInnerWallArea(innerWallLength1 * (innerWallLength2 + (innerWallThickness * 2)));
    const vol1 = roomHeight * innerWallLength1 * innerWallThickness / 27;
    const vol2 = roomHeight * innerWallLength2 * innerWallThickness / 27;

    setInnerWallVolume((vol1 * 2) + (vol2 * 2));
  }, [innerWallLength1, innerWallLength2, innerWallThickness, roomHeight]);

  // Outer Wall
  useEffect(() => {
    setOuterWallArea(outerWallLength1 * (outerWallLength2 + (outerWallThickness * 2)));

    setTotalBuildingArea(outerWallLength1 * (outerWallLength2 + (outerWallThickness * 2)));
    setTotalBuildingHeight(outerRoomHeight + (outerRoofSlabThickness / 12));
    setTotalBuildingVolume(
      (outerRoomHeight + (outerRoofSlabThickness / 12)) * outerWallLength1 * (outerWallLength2 + (outerWallThickness * 2)),
    );

    const vol1 = outerRoomHeight * outerWallLength1 * outerWallThickness / 27;
    const vol2 = outerRoomHeight * outerWallLength2 * outerWallThickness / 27;

    setOuterWallVolume((vol1 * 2) + (vol2 * 2));
  }, [outerRoofSlabThickness, outerRoomHeight, outerWallLength1, outerWallLength2, outerWallThickness]);

  // MAM Resonance
  useEffect(() => {
    const a1 = innerWallLength1 * roomHeight;
    const a2 = outerWallLength1 * outerRoomHeight;

    const {
      result1, result2, result3,
    } = helpers.massAirMassResonance(innerWallVolume, outerWallVolume, a1, a2, leafDepth);

    setMamResonance1(result1);
    setMamResonance2(result2);
    setMamResonance3(result3);
  }, [roomHeight, innerWallLength1, innerWallVolume, leafDepth, outerRoomHeight, outerWallLength1, outerWallVolume]);

  // TL
  useEffect(() => {
    const a1 = innerWallLength1 * roomHeight;
    const m1 = roomHeight * outerWallLength1 * innerWallThickness * 150;

    const a2 = outerWallLength1 * outerRoomHeight;
    const m2 = outerRoomHeight * outerWallLength1 * outerWallThickness * 150;

    const tl1 = helpers.transmissionLoss(m1 / a1);
    const tl2 = helpers.transmissionLoss(m2 / a2);

    const tl3 = [...tl1, ...tl2]
      .reduce((accumulator: TransmissionLoss[], current) => {
        const existingItemIndex = accumulator.findIndex((tl: TransmissionLoss) => tl.label === current.label);
        if (existingItemIndex !== -1) {
          accumulator[existingItemIndex] = {
            ...accumulator[existingItemIndex],
            loss: accumulator[existingItemIndex].loss + current.loss,
          };
        } else {
          accumulator.push(current);
        }

        return accumulator;
      }, []);

    setInnerWallTransmissionLoss(tl1);
    setOuterWallTransmissionLoss(tl2);
    setTotalTransmissionLoss(tl3);
  }, [roomHeight, innerWallLength1, innerWallThickness, outerRoomHeight, outerWallLength1, outerWallThickness]);

  // Modes
  useEffect(() => {
    setModes(helpers.modalFrequencies(roomHeight, roomLength1, roomLength2));
  }, [roomHeight, roomLength1, roomLength2]);

  // Inner Floor Slab
  useEffect(() => {
    setInnerFloorSlabArea(roomLength1 * roomLength2);
    setInnerFloorSlabVolume(roomLength1 * roomLength2 * (innerFloorSlabThickness / 12) / 27);
  }, [roomLength1, roomLength2, innerFloorSlabThickness]);

  // Inner Roof Slab
  useEffect(() => {
    setInnerRoofSlabArea(innerWallLength1 * (innerWallLength2 + (innerWallThickness * 2)));
    setInnerRoofSlabVolume(
      innerWallLength1 * (innerWallLength2 + (innerWallThickness * 2)) * (innerRoofSlabThickness / 12) / 27,
    );
  }, [innerRoofSlabThickness, innerWallLength1, innerWallLength2, innerWallThickness]);

  // Outer Floor Slab
  useEffect(() => {
    setOuterFloorSlabArea(outerWallLength1 * outerWallLength2);
    setOuterFloorSlabVolume((outerWallLength1 * outerWallLength2 * (outerFloorSlabThickness / 12) / 27) - innerFloorSlabVolume);
  }, [innerFloorSlabVolume, outerWallLength1, outerWallLength2, outerFloorSlabThickness]);

  // Outer Roof Slab
  useEffect(() => {
    setOuterRoofSlabArea(outerWallLength1 * (outerWallLength2 + (outerWallThickness * 2)));
    setOuterRoofSlabVolume(outerWallLength1 * (outerWallLength2 + (outerWallThickness * 2)) * (outerRoofSlabThickness / 12) / 27);
  }, [outerWallLength1, outerWallLength2, outerWallThickness, outerRoofSlabThickness]);

  // Total Concrete Area & Volume
  useEffect(() => {
    const innerVolume = innerFloorSlabVolume + innerRoofSlabVolume + innerWallVolume;
    const outerVolume = outerFloorSlabVolume + outerRoofSlabVolume + outerWallVolume;

    setTotalConcreteMass((innerVolume + outerVolume) * 27 * convertMetricVolume(Material.Concrete.density));
    setTotalConcreteVolume(innerVolume + outerVolume);
  }, [innerFloorSlabVolume, innerRoofSlabVolume, innerWallVolume, outerFloorSlabVolume, outerRoofSlabVolume, outerWallVolume]);

  useEffect(() => {
    setPrice(totalConcreteVolume * costOfConcrete);
  }, [costOfConcrete, totalConcreteVolume]);

  useEffect(() => {
    const labor = (price * 2) * (laborMultiplier / 100);
    const overage = (price + labor) * (overageMultiplier / 100);
    setLaborPrice(labor);
    setOveragePrice(overage);
    setTotalPrice(price + labor + overage);
  }, [laborMultiplier, price, overageMultiplier]);

  return (
    <CalculatedContext.Provider
      value={{
        innerFloorSlabArea,
        innerFloorSlabVolume,
        innerRoofSlabArea,
        innerRoofSlabVolume,
        innerWallArea,
        innerWallLength1,
        innerWallLength2,
        innerWallTransmissionLoss,
        innerWallVolume,
        laborPrice,
        mamResonance1,
        mamResonance2,
        mamResonance3,
        modes,
        outerRoomHeight,
        outerWallArea,
        outerWallLength1,
        outerWallLength2,
        outerWallTransmissionLoss,
        outerWallVolume,
        outerFloorSlabArea,
        outerFloorSlabVolume,
        outerRoofSlabArea,
        outerRoofSlabVolume,
        overagePrice,
        price,
        roomArea,
        roomVolume,
        totalBuildingArea,
        totalBuildingHeight,
        totalBuildingVolume,
        totalConcreteMass,
        totalConcreteVolume,
        totalPrice,
        totalTransmissionLoss,
      }}
    >
      {children}
    </CalculatedContext.Provider>
  );
};

export default {
  Context: CalculatedContext,
  Provider,
};
