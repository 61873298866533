interface IMaterial {
  density: number;
  poissonsRatio?: number;
  youngsModulus?: number | string;
}

export const Material: { [index: string]: IMaterial } = {
  Air: {
    density: 1.293,
  },
  'Aluminum [2024]': {
    density: 2780,
    poissonsRatio: 0.33,
    youngsModulus: 73.4,
  },
  'Aluminum [6061]': {
    density: 2700,
    poissonsRatio: 0.33,
    youngsModulus: 68.9,
  },
  'Carbon Fiber': {
    density: 1750,
    poissonsRatio: 0.27,
    youngsModulus: 350,
  },
  Concrete: {
    density: 2300,
    poissonsRatio: 0.2,
    youngsModulus: 40,
  },
  Copper: {
    density: 8690,
    poissonsRatio: 0.34,
    youngsModulus: 130,
  },
  'Drywall [1/2 in]': {
    density: 38,
  },
  Glass: {
    density: 2500,
    poissonsRatio: 0.24,
    youngsModulus: 60,
  },
  Gold: {
    density: 19300,
    poissonsRatio: 0.4,
    youngsModulus: 79,
  },
  Lead: {
    density: 11343,
    poissonsRatio: 0.4,
    youngsModulus: 16,
  },
  Osmium: {
    density: 22590,
    poissonsRatio: .25,
    youngsModulus: 560,
  },
  Silver: {
    density: 10490,
    poissonsRatio: 0.37,
    youngsModulus: 83,
  },
  'Stainless Steel [304]': {
    density: 7930,
    poissonsRatio: 0.265,
    youngsModulus: 190,
  },
  Water: {
    density: 997,
    poissonsRatio: 0.5,
    youngsModulus: '-',
  },
};

export const SpeedOfSound = 343;

export default { Material, SpeedOfSound };
