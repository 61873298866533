export enum ModeType {
  Axial = 'Axial',
  Oblique = 'Oblique',
  Tangential = 'Tangential',
}

export interface Mode {
  dimension: string;
  frequency: number;
  type: ModeType;
  wavelength: number;
}

export interface TransmissionLoss {
  label: string;
  loss: number;
}
