import React from 'react';

import { App, Calculated } from './src/context';

import 'bootstrap/dist/css/bootstrap.css';

export const wrapRootElement = ({ element }: any) => (
  <App.Provider>
    <Calculated.Provider>
      {element}
    </Calculated.Provider>
  </App.Provider>
);
