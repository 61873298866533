import { Mode, ModeType } from '@types';
import { Material, SpeedOfSound } from '@utils/constants';

const transmissionLossFrequencies = [16, 31, 63, 125, 250, 500, 1000, 2000, 4000, 8000];

function ftToM(ft: number) {
  return ft / 3.2808;
}

function sqFtToSqM(sFt: number) {
  return sFt / 10.764;
}

function cubicYdToCubicM(yd: number) {
  return yd / 1.308;
}

const massAirMassResonance = (v1: number, v2: number, a1: number, a2: number, depth: number) => {
  // mass 1 = v * p
  const m1 = cubicYdToCubicM(v1) * Material.Concrete.density;

  // mass 2 = v * p
  const m2 = cubicYdToCubicM(v2) * Material.Concrete.density;

  const surfaceDensity1 = m1 / sqFtToSqM(a1);
  const surfaceDensity2 = m2 / sqFtToSqM(a2);

  // Paper
  const result1 = 1 / 2 * Math.PI * Math.sqrt(
    (Material.Air.density * SpeedOfSound * (surfaceDensity1 + surfaceDensity2)) /
    (ftToM(depth) * surfaceDensity1 * surfaceDensity2),
  );

  // MAM LibreCalc
  const angleOfIncidence = 25;
  const result2 = 60 / (Math.cos(angleOfIncidence * Math.PI / 180) * Math.sqrt(ftToM(depth))) * Math.sqrt((1 / (ftToM(1) * Material.Concrete.density)) + (1 / (ftToM(1) * Material.Concrete.density)));

  // Book
  const result3 = 54.59 * Math.sqrt((surfaceDensity1 + surfaceDensity2) / (ftToM(depth) * surfaceDensity1 * surfaceDensity2));

  // const result4 = (SpeedOfSound / 2 * Math.PI) * Math.sqrt((K₁ + K₂) / (m1 + m2));

  return { result1, result2, result3 };
};

const modalFrequencies = (height: number, length1: number, length2: number): Mode[][] => {
  const modeLimit = 10;
  const axial: Mode[][] = [];

  function calculate(d1: number, d2: number, d3: number, p: number, q: number, r: number) {
    return SpeedOfSound / 2 * Math.sqrt(Math.pow(p / d1, 2) + Math.pow(q / d2, 2) + Math.pow(r / d3, 2));
  }

  for (let i = 0; i < modeLimit; i++) {
    const fL = calculate(length1, length2, height, i + 1, 0, 0);
    const fW = calculate(length1, length2, height, 0, i + 1, 0);
    const fH = calculate(length1, length2, height, 0, 0, i + 1);

    const imperialSpeedOfSound = SpeedOfSound * 3.281;
    axial.push([
      { frequency: fL, dimension: 'L', type: ModeType.Axial, wavelength: imperialSpeedOfSound / fL },
      { frequency: fW, dimension: 'W', type: ModeType.Axial, wavelength: imperialSpeedOfSound / fW },
      { frequency: fH, dimension: 'H', type: ModeType.Axial, wavelength: imperialSpeedOfSound / fH },
    ]);
  }

  return axial;
};

const transmissionLoss = (surfaceDensity: number) => {
  return transmissionLossFrequencies.map((f) => ({ label: `${f} Hz`, loss: 20 * Math.log10(f * surfaceDensity) - 33 }));
};

export default { massAirMassResonance, modalFrequencies, transmissionLoss };
