import React, { createContext, useReducer } from 'react';

export enum ActionType {
  COST_OF_CONCRETE = 'costOfConcrete',
  INNER_FLOOR_SLAB_THICKNESS = 'innerFloorSlabThickness',
  INNER_ROOF_SLAB_THICKNESS = 'innerRoofSlabThickness',
  INNER_WALL_THICKNESS = 'innerWallThickness',
  LABOR_MULTIPLIER = 'laborMultiplier',
  LEAF_DEPTH = 'leafDepth',
  OUTER_FLOOR_SLAB_THICKNESS = 'outerFloorSlabThickness',
  OUTER_ROOF_SLAB_THICKNESS = 'outerRoofSlabThickness',
  OUTER_WALL_THICKNESS = 'outerWallThickness',
  OVERAGE_MULTIPLIER = 'overageMultiplier',
  ROOM_HEIGHT = 'roomHeight',
  ROOM_LENGTH1 = 'roomLength1',
  ROOM_LENGTH2 = 'roomLength2',
  UNITS = 'units',
}

enum UnitType {
  IMPERIAL = 'imperial',
  METRIC = 'metric',
}

interface IAppState {
  costOfConcrete: number;
  innerFloorSlabThickness: number;
  innerRoofSlabThickness: number;
  innerWallThickness: number;
  laborMultiplier: number;
  leafDepth: number;
  outerFloorSlabThickness: number;
  outerRoofSlabThickness: number;
  outerWallThickness: number;
  overageMultiplier: number;
  roomHeight: number;
  roomLength1: number;
  roomLength2: number;
  units: UnitType;
}

interface IAppContext {
  dispatch: React.Dispatch<Action>;
  state: IAppState;
}

type Action = { key: ActionType, value: number };

const AppContext = createContext({} as IAppContext);

const defaultAppValues = {
  costOfConcrete: 125,
  roomHeight: 12,
  roomLength1: 23,
  roomLength2: 18,
  innerFloorSlabThickness: 4,
  innerRoofSlabThickness: 4,
  innerWallThickness: 1,
  laborMultiplier: 50,
  leafDepth: 1,
  outerFloorSlabThickness: 6,
  outerRoofSlabThickness: 6,
  outerWallThickness: 1,
  overageMultiplier: 0,
  units: UnitType.IMPERIAL,
};

const Reducer = (state: IAppState, action: Action): IAppState => {
  return {
    ...state,
    ...{ [action.key]: action.value },
  };
};

const Provider = ({ children }: any) => {
  const [state, dispatch] = useReducer(Reducer, defaultAppValues);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default {
  Actions: ActionType,
  Context: AppContext,
  Provider,
  Reducer,
  defaultAppValues,
  UnitType,
};
